import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "./index.scss"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tools
        year
      }
      html
    }
  }
`

const Project = ({ data: { markdownRemark }, pageContext: { prev, next } }) => (
  <div className="project">
    <Layout
      isProject
      prev={prev && prev.node.fields.slug}
      next={next && next.node.fields.slug}
    >
      <SEO title={markdownRemark.frontmatter.title} />
      <h1 className="project__title">{markdownRemark.frontmatter.title}</h1>
      <h3 className="project__meta">
        {`${markdownRemark.frontmatter.year} - ${markdownRemark.frontmatter.tools}`}
      </h3>
      <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
    </Layout>
  </div>
)

export default Project
