import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import cx from "classnames"
import "./index.scss"

const Navigation = ({ siteTitle, isProject, prev, next, toggleContact }) => {
  const [isToggleButtonActive, setIsToggleButtonActive] = useState(false)
  function toggleButton() {
    setIsToggleButtonActive(!isToggleButtonActive)
  }

  if (isProject) {
    return (
      <nav className="nav project__nav">
        <div className="project__nav__wrapper">
          <Link to="/" className="nav__link project__nav__wrapper__link">
            <span className="nav__link--close">close</span>
          </Link>
          <Link
            to={prev ? `/projects/${prev}` : ""}
            className={cx("nav__link project__nav__wrapper__link", {
              "nav__link--disabled": !prev,
            })}
          >
            previous project
          </Link>
          <Link
            to={next ? `/projects/${next}` : ""}
            className={cx("nav__link project__nav__wrapper__link", {
              "nav__link--disabled": !next,
            })}
          >
            next project
          </Link>
        </div>
      </nav>
    )
  }

  const socialLinks = [
    {
      label: "github",
      link: "https://github.com/chaddanna",
    },
    { label: "twitter", link: "https://twitter.com/chaddanna" },
  ]
  const navPoints = ["work", "skills", "about"]
  return (
    <nav className="nav">
      <div className="nav__toggle">
        <a className="nav__title" href="#intro">
          {siteTitle}
        </a>
        <div>
          <button
            className="nav__button nav__toggle__button"
            onClick={toggleButton}
          >
            <div className="nav__toggle__line"></div>
            <div className="nav__toggle__line"></div>
          </button>
        </div>
      </div>
      <div
        className={cx("nav__hidden", {
          "nav__hidden--active": isToggleButtonActive,
        })}
      >
        <div className="nav__social">
          {socialLinks.map(sl => (
            <a
              key={sl.label}
              className="nav__link"
              href={sl.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {sl.label}
            </a>
          ))}
        </div>
        <div className="nav__title">2011-{new Date().getFullYear()}</div>
        <div className="nav__points">
          {navPoints.map(np => (
            <a key={np} className="nav__link" href={`#${np}`}>
              {np}
            </a>
          ))}
          <button className="nav__button" onClick={toggleContact}>
            contact
          </button>
        </div>
      </div>
    </nav>
  )
}

Navigation.propTypes = {
  isContact: PropTypes.bool,
  toggleContact: PropTypes.func.isRequired,
  isProject: PropTypes.bool,
  prev: PropTypes.string,
  next: PropTypes.string,
  siteTitle: PropTypes.string.isRequired,
}

Navigation.defaultProps = {
  isContact: false,
  isProject: false,
  siteTitle: ``,
  toggleContact: () => {},
}

export default Navigation
