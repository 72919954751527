import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navigation from "../navigation"
import "../../sass/base.scss"
import "./index.scss"

const Layout = ({
  children,
  isProject,
  prev,
  next,
  isContact,
  toggleContact,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className="main">
      <Navigation
        siteTitle={data.site.siteMetadata.title}
        isProject={isProject}
        prev={prev}
        next={next}
        toggleContact={toggleContact}
      />
      <div className="main__layout">{children}</div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isProject: PropTypes.bool,
  prev: PropTypes.string,
  next: PropTypes.string,
  toggleContact: PropTypes.func,
}

export default Layout
